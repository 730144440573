@font-face {
  font-display: fallback;
  font-family: 'Libre-Baskerville-Reg';
  font-style: normal;
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
    url('fonts/libre-baskerville-v5-latin-regular.woff2') format('woff2'),
    url('fonts/libre-baskerville-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-display: fallback;
  font-family: 'Serif420-Regular';
  font-style: normal;
  src: local('Serif420'), local('Serif420-Regular'),
    url('fonts/Serif420-Regular.otf') format('opentype');
}

@font-face {
  font-display: fallback;
  font-family: 'Serif420-Italic';
  font-style: italic;
  src: local('Serif420 Italic'), local('Serif420-Italic'),
    url('fonts/Serif420-Italic.otf') format('opentype');
}

html {
  font-family: 'Serif420-Regular', Helvetica, sans-serif;
  font-style: italic;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
